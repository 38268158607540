angular.module('psModule').controller('AssetsCtrl', function($scope, $window, assetService, benefitService, $rootScope) {

    $scope.currentTab = 1;
    $scope.tillgangsvardeLoaded = false;
    $scope.insuranceAssetsLoaded = false;
    $scope.pensionSortBy = 'name';
    $scope.pensionSortRevert = false;
    $scope.otherSortBy = 'name';
    $scope.otherSortRevert = false;

    function getPieData(assets) {
        var pieData = [];
        for (var i = 0; i < assets.length; i++) {
            pieData.push(
                [assets[i].name, assets[i].value]
            );
        }
        return pieData;
    }

    function getAssets(tillgangsvarden) {
        var tillgangar = [];
        for (var i = 0; i < tillgangsvarden.length; i++) {
            tillgangar.push({
                id: tillgangsvarden[i].tillgang.id,
                isin: tillgangsvarden[i].tillgang.isin,
                name: tillgangsvarden[i].tillgang.name,
                sharesDate: tillgangsvarden[i].tillgang.sharesDate,
                shares: tillgangsvarden[i].tillgang.shares,
                shareOfPremium: tillgangsvarden[i].tillgang.shareOfPremium,
                securityId: tillgangsvarden[i].tillgang.securityId,
                universeId: tillgangsvarden[i].tillgang.universeId,
                type: tillgangsvarden[i].tillgang.type,
                purpose: tillgangsvarden[i].tillgang.purpose,
                value: tillgangsvarden[i].value,
                containerNames: [tillgangsvarden[i].kapitalskal.name],
                containerType: tillgangsvarden[i].kapitalskal.typ
            });
        }
        return tillgangar;
    }

    function addToAssets(tillgang, tillgangar) {
        var index = findMatchById(tillgangar, tillgang.id);
        if (index === -1) {
            tillgangar.push(tillgang);
        } else {
            tillgangar[index].containerNames.push(tillgang.containerNames[0]);
            tillgangar[index].value += tillgang.value;
            var newDate = tillgang.sharesDate;
            if (newDate !== null && newDate > tillgangar[index].sharesDate) {
                tillgangar[index].sharesDate = newDate;
            }
        }
    }

    function getTotalAssetValue(assets) {
        var total = 0;
        for (var i = 0; i < assets.length; i++) {
            total += assets[i].value;
        }
        return total;
    }

    function findMatchById(array, id) {
        for (var i = 0; i < array.length; i++) {
            if (array[i].id === id) {
                return i;
            }
        }
        return -1;
    }

    $scope.setTab = function(tab) {
        $scope.currentTab = tab;
    };

    function canUseAssetInAnalysis(asset) {
        return asset.usableInAnalysis;
    }

    function initializeXray(insuranceAssets) {
        function insurances() {
            return insuranceAssets;
        }

        function insurancesSelected() {
            return insurances().filter(function(insurance) {
                return insurance.selectedForAnalysis;
            });
        }

        function canUseForAnalysis(insurance) {
            return insurance.assets.filter(canUseAssetInAnalysis).length > 0;
        }

        $scope.assetsToAnalyze = function() {
            return _.flatten(insurancesSelected().map(function(c) {
                return _.flatten(c.assets.filter(canUseAssetInAnalysis).map(function(asset) {
                    return {
                        isin: asset.isin,
                        value: asset.value
                    };
                }));
            }));
        };

        $scope.anyAssetsToAnalyze = function() {
            return $scope.assetsToAnalyze().length > 0;
        };

        $scope.xrayFilter = {
            canUseForAnalysis: canUseForAnalysis
        };
    }

    assetService.getInsuranceAssets().$promise.then(function(insuranceAssets) {
        $scope.insuranceAssets = insuranceAssets;
        $scope.sumInsuranceAssets = _.reduce(insuranceAssets, function (sum, insuranceAsset) {
            return sum + insuranceAsset.value;
        }, 0);

        initializeXray(insuranceAssets);
        $scope.insuranceAssetsLoaded = true;
    });

    assetService.get().$promise.then(function(tillgangsvarden) {
        var pensionAssets = [];
        var otherAssetsNoDebts = [];
        var otherDebts = [];
        var assets = getAssets(tillgangsvarden);
        for (var i = 0; i < assets.length; i++) {
            if (assets[i].purpose === 'Pension') {
                addToAssets(assets[i], pensionAssets);
            }
            if (assets[i].purpose === 'Other') {
                addToAssets(assets[i], assets[i].type === 'Skuld' ? otherDebts : otherAssetsNoDebts);
            }
        }
        $scope.pensionAssets = pensionAssets;
        $scope.otherAssetsNoDebts = otherAssetsNoDebts;
        $scope.otherAssetsAndDebts = otherAssetsNoDebts.concat(otherDebts);

        $scope.totalPension = getTotalAssetValue($scope.pensionAssets);
        $scope.totalOtherAndDebts = getTotalAssetValue($scope.otherAssetsAndDebts);
        $scope.total = $scope.totalPension + $scope.totalOtherAndDebts;

        $scope.pieDataPension = getPieData($scope.pensionAssets);
        $scope.pieDataOther = getPieData($scope.otherAssetsNoDebts);

        $scope.tillgangsvardeLoaded = true;
    });
});
