angular.module('fondbyte')
    .controller('ModifyCtrl', function ($scope, $state, $filter, $window, $uiRouterGlobals, ChangeService, fundChangeableService, instrumentService, Message, contentfileFilter) {

        var noIsinErrorName = 'no-isin',
            noInstrumentsErrorName = 'no-instruments',
            fundsNotInUniversErrorName = 'fundsNotInUnivers',
            policyNumber = $uiRouterGlobals.params.policyNumber,
            useExisting = $uiRouterGlobals.params.continue,
            translate = $filter('translate');

        $scope.funds = [];
        $scope.instruments = {};
        $scope.fundMissingIsin = false;
        $scope.fundsNotInUnivers = [];
        $scope.sortBy = 'name';
        $scope.sortRevert = false;
        $scope.currentTab = 1;
        $scope.hasActivatedPortfolio = false;
        $scope.electronicFundChangeOpen = true; // Start with true to prevent flashing of information box on loading.

        var init = function() {
            ChangeService.isElectronicFundChangeOpen().then(function (result) {
                $scope.electronicFundChangeOpen = result;
            });
        };

        // Initialize controller stuff
        init();

        $scope.useModelPortfolio = function (modelPortfolio) {
            $scope.hasActivatedPortfolio = true;
            $scope.order.setPortfolio(modelPortfolio);
            document.getElementById('orderTitle').scrollIntoView();
        };

        $scope.setMainTab = function(index) {
            $scope.currentTab = index;
        };

        fundChangeableService.getFundChangeableInsurances().then(function (insurances) {
            var insurance = _.first(_.where(insurances, {
                forsakringsNummer: policyNumber
            }));

            if (!insurance || !insurance.metaData.isChangeable) {
                $state.go('fondbyte');
                return;
            }

            $scope.order = ChangeService.createReallocationOrder(insurance, useExisting);
            $scope.branding = insurance.companyShortCode;
            $scope.brandinfo = function() {
                return contentfileFilter(insurance.companyShortCode + '_{lang}.html', '/fondbyte/brand');
            };
            $scope.insuranceCompanyOrgNo = insurance.orgNo;

            $scope.instrumentsPromise = instrumentService.getInstrumentsForUnivers($scope.order.instrumentGroupKey).$promise;
            $scope.instrumentsPromise.then(function (instruments) {
                if (_.isEmpty(instruments)) {
                    $scope.couldNotLoadInstruments = true;
                    var errorMessage = translate('fb_error_could_not_load_instruments') + ' ' + translate('ps_contact_support');
                    Message.error(translate('ps_technical_error'), errorMessage, noInstrumentsErrorName);
                } else {
                    Message.resolveMessage(noInstrumentsErrorName);
                    $scope.funds = instruments;
                }
            });
        });

        $scope.gotoConfirm = function() {
            if (!$scope.isElectronicFundChangeOpen()) {
                Message.error('fb_fund_change_market_closed_err', 'fb_fund_change_market_closed_err_message', 'nochangesError');
                $window.scrollTo(0, 0);
            } else if (!$scope.order.isComplete() || !$scope.order.isChanged() || $scope.fundMissingIsin || !_.isEmpty($scope.fundsNotInUnivers)) {
                if (!$scope.order.isComplete()) {
                    Message.error('fb_innehav_err', 'fb_innehav_err_message', 'innehavError');
                    $window.scrollTo(0, 0);
                } else if (!$scope.order.isChanged()) {
                    Message.error('fb_nochanges_err', 'fb_nochanges_err_message', 'nochangesError');
                    $window.scrollTo(0, 0);
                }
            } else {
                ChangeService.saveReallocationOrder($scope.order);
                $state.go('fondbyteBekrafta');
            }
        };

        $scope.goToAddFunds = function() {
            $scope.setMainTab(1);
            document.getElementById('tab-content').scrollIntoView();
        };

        $scope.hasNonDailyFunds = function (funds) {
            return funds.filter(isNonDailyFund).length > 0;
        };

        $scope.orderHasNonDailyFunds = function () {
            if ($scope.checkDefined($scope.order, 'innehav')) {

                var sparfordelning = $scope.order.innehav;

                return sparfordelning.filter(function(s) {
                    return isNonDailyFund($scope.getFund(s));
                }).length > 0;
            }
            return false;
        };

        $scope.fundIsNonDaily = function(sparfordelning) {
            return isNonDailyFund($scope.getFund(sparfordelning));
        };

        function isNonDailyFund(fund) {
            return (angular.isDefined(fund) && fund !== null) && fund.pricingFrequency !== 'DAILY';
        }

        $scope.innehavHasDiscountedFunds = function () {
            if ($scope.checkDefined($scope.order, 'innehav')) {

                var sparfordelning = $scope.order.innehav;

                return sparfordelning.filter(function(s) {
                        return $scope.fundIsDiscounted($scope.getFund(s));
                    }).length > 0;
            }
            return false;
        };

        $scope.hasDiscountedFunds = function (funds) {
            return funds.filter($scope.fundIsDiscounted).length > 0;
        };

        $scope.fundIsDiscounted = function(fund){
            return !!fund && !!fund.managementFee && !!fund.discountedManagementFee && fund.managementFee > fund.discountedManagementFee;
        };

        $scope.getFund = function (sparfordeling) {
            if (sparfordeling.innehav.isin) {
                var key = sparfordeling.innehav.name.replace(/\s+/g, '');
                var inst = $scope.instruments[key];

                if (!angular.isUndefined(inst)) {
                    return inst;
                } else {
                    var fundPromise = $scope.getFundPromise(sparfordeling);
                    fundPromise.then(function (i) {
                        var fund = angular.isUndefined(i) ? null : i;
                        if (!$scope.instruments.hasOwnProperty(key)) {
                            $scope.instruments[key] = fund;
                        }
                        return fund;
                    });
                }
            }
        };

        $scope.getFundPromise = function (sparfordeling) {
            return instrumentService.getInstrument($scope.order.instrumentGroupKey, sparfordeling.innehav.isin);
        };

        $scope.isElectronicFundChangeOpen = function() {
            return !!$scope.electronicFundChangeOpen;
        };

        $scope.$watch('fundMissingIsin', function (n, o) {
            if ($scope.fundMissingIsin === true) {
                Message.error('ps_fund_missing_isin', 'ps_missing_isin_message', noIsinErrorName);
            } else {
                Message.resolveMessage(noIsinErrorName);
            }
        });

        $scope.$watch('fundsNotInUnivers', function (n, o) {
            if (!_.isEmpty($scope.fundsNotInUnivers)) {
                Message.error('fb_funds_not_in_univers', 'fb_funds_not_in_univers_message', fundsNotInUniversErrorName);
            } else {
                Message.resolveMessage(fundsNotInUniversErrorName);
            }
        });

        $scope.$watch('order.isComplete()', function (n, o) {
            if ($scope.order && $scope.order.isComplete()) {
                Message.resolveMessage('innehavError');
            }
        });

        $scope.$watch('order', function (n, o) {
            if ($scope.order) {
                $scope.fundMissingIsin = _.some($scope.order.innehav, function (sparfordelning) {
                    return !sparfordelning.innehav.isin;
                });

                $scope.instrumentsPromise.then(function (instruments) {
                    if (!_.isEmpty(instruments)) {
                        $scope.fundsNotInUnivers = _.filter($scope.order.innehav, function (sparfordelning) {
                            var existsInUnivers = _.find(instruments, function (fund) {
                                return sparfordelning.innehav.isin === fund.isin;
                            });
                            if (!existsInUnivers) {
                                sparfordelning.innehav.fundNotInUnivers = true;
                            }
                            return !existsInUnivers;
                        });
                    }
                });

                if ($scope.order.isChanged()) {
                    Message.resolveMessage('nochangesError');
                }
            }
        }, true);
    }
);
