angular.module('psModule').factory('FeedService', function ($resource, $q, $rootScope) {
    var feedService = {};

    var defer = $q.defer();

    var feedResource = $resource('/ps/api/feed/titles/', {hits: '@hits'}, {});

    feedService.getFeed = function ( maxHits) {
        feedResource.get({ hits: maxHits},
            function (response) {
                var news = [];
                if ($rootScope.checkDefined(response, 'entries')) {
                    news = response.entries;
                }
                defer.resolve(news);
            },
            function (error) {
                defer.reject(error);
            });
        return defer.promise;
    };

    return feedService;
});
