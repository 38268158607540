angular.module('maxfonder').factory('MaxfonderService', ['$resource', function ($resource) {

    var service = {};

    var resource = $resource(null, null, {
        'getBanks': {
            url: '/ps/api/maxfonder/banks',
            method: 'GET',
            isArray: true
        },
        'isActive': {
            url: '/ps/api/maxfonder/active',
            method: 'GET',
            transformResponse: function (data) {
                return {isActive: data === 'true'
            };
        }},
        'isValid': {
            url: '/ps/api/maxfonder/valid',
            method: 'GET',
            transformResponse: function (data) {
                return {isValid: data === 'true'
            };
        }},
        'getBrokersAndCodes': {
            url: '/ps/api/maxfonder/brokers',
            method: 'GET',
            isArray:true
        },
        'getFees': {
            url: '/ps/api/maxfonder/fees',
            method: 'GET',
            isArray:true
        },
        'getPortfolioTypes': {
            url: '/ps/api/maxfonder/portfoliotypes',
            method: 'GET',
            isArray:true
        },
        'getPortfoliosOfType': {
            url: '/ps/api/maxfonder/portfolios/:typeId',
            method: 'GET',
            isArray: true,
            typeId: '@typeId'
        },
    });

    service.getBanks = function () {
        return resource.getBanks();
    };

    service.isActive = function () {
        return resource.isActive();
    };

    service.isValid = function () {
        return resource.isValid();
    };

    service.getFees = function () {
        return resource.getFees();
    };

    service.getPortfolioTypes = function () {
        return resource.getPortfolioTypes();
    };

    service.getPortfoliosOfType = function(typeId) {
        return resource.getPortfoliosOfType({typeId: typeId});
    };

    service.getBrokersAndCodes = resource.getBrokersAndCodes;

    return service;
}]);
