angular.module('psModule').controller('DocumentsCtrl',
    function ($scope, $rootScope, $filter, DocumentService, Message, TogglzService, $window, TokenService, DocumentStatefulService) {

        TogglzService.enabled().then(function (response) {
            $scope.fileUploadEnabled = $scope.user.broker && response.data;
        });

        $scope.$on('onUploadCompete', function () {
            getData();
        });

        $scope.changeVisibility = function (event, document) {
            event.stopPropagation();
            DocumentService.changeVisibility({id: document.customerDocumentId}, !document.hidden, function () {
                document.hidden = !document.hidden;
            });
        };

        $scope.dataLoaded = false;
        $scope.sortBy = 'date';
        $scope.sortRevert = true;
        $scope.documentType = undefined;
        $scope.documentTypeOptions = [];
        $scope.allDocumentTypes = [];
        $scope.allUploadableDocumentTypes = [];
        $scope.allDocumentTypeOptions = [];
        $scope.allUploadableDocumentTypeOptions = [];

        $scope.showPopover = false;

        function setDocumentTypeOptions() {
            let uniqueDocumentTypes = _.unique(_.map($scope.documents, 'type'));
            $scope.documentTypeOptions = _.sortBy(_.map(uniqueDocumentTypes, function (type) {
                return {
                    id: type,
                    label: $filter('translate')('ps_documents_type_' + type)
                }
            }), 'label');
            $scope.documentTypeOptions.unshift({id: undefined, label: $filter('translate')('ps_documents_type_all')});
        }

        function setAllDocumentTypeOptions() {
            $scope.allDocumentTypeOptions = _.sortBy(_.map($scope.allDocumentTypes, function (type) {
                return {
                    id: type,
                    label: $filter('translate')('ps_documents_type_' + type)
                }
            }), 'label');
            $scope.allDocumentTypeOptions.unshift({id: undefined, label: $filter('translate')('ps_documents_upload_type_choose')});
        }

        function setAllUploadableDocumentTypeOptions() {
            $scope.allUploadableDocumentTypeOptions = _.sortBy(_.map($scope.allUploadableDocumentTypes, function (type) {
                return {
                    id: type,
                    label: $filter('translate')('ps_documents_type_' + type)
                }
            }), 'label');
            $scope.allUploadableDocumentTypeOptions.unshift({id: undefined, label: $filter('translate')('ps_documents_upload_type_choose')});
        }

        $scope.toggleRead = function(event, document){
            event.stopPropagation();
            let read = !$scope.unreadDocuments.includes(document.documentViewId);
            toggleReadState(document, !read);
        };

        function toggleReadState(document, read) {
            DocumentStatefulService.toggleRead(document, read).then(() => {
                DocumentStatefulService.getYourDocuments().then((data) => {
                        $scope.unreadDocuments = data.unreadDocuments;
                        $rootScope.$emit('$rootScope:$emit:toggledRead', $scope.unreadDocuments.length);
                    }
                );
            });
        }

        $scope.openUserDocument = function(document) {
            toggleReadState(document, true);
            $scope.openDocument(document);
        };

        $scope.openDocument = function(document) {
            console.log("openDocument:", document);
            if (!!document.customerDocumentId) {
                let url = "/ps/api/documents/customerDocument/" + document.customerDocumentId;
                let targetUrl = TokenService.attachPsTokenToUrl(url);
                $window.open(targetUrl, '_blank');

            } else if (document.type === 'RADGIVNINGSDOKUMENTATION') {
                let url = "/ps/api/counselling/pdf/" + document.id;
                if (!!document.informationGatheringId) {
                    url = url + "?informationGatheringId=" + document.informationGatheringId;
                }
                let targetUrl = TokenService.attachPsTokenToUrl(url);
                $window.open(targetUrl, '_blank');

            } else if (document.type === 'FORSAKRINGSBESKED') {
                let url = "/ps/api/counselling/insuranceStatement/pdf/" + document.id;
                let targetUrl = TokenService.attachPsTokenToUrl(url);
                $window.open(targetUrl, '_blank');
            }
        };

        function getData(){
            DocumentStatefulService.getYourDocuments().then((data) => {
                    $scope.documents = data.documents;
                    $scope.unreadDocuments = data.unreadDocuments;
                    $scope.dataLoaded = true;
                    setDocumentTypeOptions();
                }
            );

            DocumentService.getDocumentTypes(function (data) {
                $scope.allDocumentTypes = data;
                setAllDocumentTypeOptions();
            });

            DocumentService.getUploadableDocumentTypes(function (data) {
                $scope.allUploadableDocumentTypes = data;
                setAllUploadableDocumentTypeOptions();
            });

            $scope.dataLoaded = true;

        }

        getData();

        $scope.isUnRead = function(document){
            return $scope.unreadDocuments.includes(document.documentViewId);
        };

        let refreshDocumentTypeOptions = $rootScope.$on('$translateChangeSuccess', function() {
            setDocumentTypeOptions();
            setAllDocumentTypeOptions();
        });

        $scope.$on('$destroy', function () {
            refreshDocumentTypeOptions();
        });
    }
);
