angular.module('maxfonder').factory('OrderService', function ($rootScope, $q, $http, $resource, $filter, RegistrationService, BlobService) {

    var dataTransformer = function (data, headers) {
        return {
            data: data,
            depositNumber: headers('x-maxfonder-depositnumber')
        };
    };

    var resource = $resource(null, null, {
        'sendPurchase': {url: '/ps/api/maxfonder/purchase/signed', method: 'POST'},
        'generatePurchasePdf': {
            url: '/ps/api/maxfonder/purchase/pdf',
            method: 'POST',
            responseType: 'arraybuffer',
            transformResponse: dataTransformer
        },
        'createDepositAndGenerateMergedPdf': {
            url: '/ps/api/maxfonder/registrationAndPurchase',
            method: 'POST',
            responseType: 'arraybuffer',
            transformResponse: dataTransformer
        },
        'generateMergedPdf': {
            url: '/ps/api/maxfonder/registrationAndPurchase/pdf',
            method: 'POST',
            responseType: 'arraybuffer',
            transformResponse: dataTransformer
        },
        'createDepositAndSendRegistrationAndPurchase': {url: '/ps/api/maxfonder/registrationAndPurchase/signed', method: 'POST'},
        'getDeposits': {url: '/ps/api/maxfonder/depositNumbers', method: 'GET', isArray: true}
    });

    var service = {};

    service.getDeposits = function () {
        return resource.getDeposits();
    };

    service.hasPendingSavedRegistration = function () {
        return RegistrationService.hasPendingSavedRegistration();
    };

    service.getSavedRegistration = function () {
        if (RegistrationService.hasPendingSavedRegistration()) {
            return RegistrationService.getSavedRegistration();
        }
    };

    function createOrder(savedOrder) {
        var order;

        if (savedOrder) {
            order = savedOrder;
        } else {
            order = {
                ssn: $rootScope.user.ssn,
                selectedFunds: [],
                portfolio: {
                    portfolioTypeId: null,
                    portfolioTypeName: null,
                    portfolioName: null,
                    monthlyAmount: null,
                    initialAmount: null
                },
                depositNumber: null,
                eSigningSelected: false,
                pdf: null,
                pdfId: null,
                fundPurchases: [],
                submitted: false,
                debitMonthDay: null
            };
        }

        order.generateFundPurchases = function (portfolioSelected) {
            order.fundPurchases.splice(0, order.fundPurchases.length);
            if (!portfolioSelected) {
                order.selectedFunds.forEach(function (fund) {
                    order.fundPurchases.push({
                        portfolioTypeId: undefined,
                        portfolioTypeName: undefined,
                        portfolioName: undefined,
                        fundName: fund.name,
                        isin: fund.isin,
                        monthlyAmount: fund.monthlyAmount,
                        initialAmount: fund.initialAmount
                    });
                });
            }
            else {
                order.fundPurchases.push(order.portfolio);
            }
        };

        order.createSignDataInput = function () {
            if (RegistrationService.hasPendingSavedRegistration()) {
                return {
                    registrationVisibleDataInput: RegistrationService.getSavedRegistration().createSignDataInput(),
                    purchaseVisibleDataInput: order.fundPurchases
                };
            }

            return order.fundPurchases;
        };

        //Manipulate selected funds
        order.addSelectedFund = function (fund) {
            order.selectedFunds.push(fund);
        };

        order.removeSelectedFund = function (fund) {
            var index = order.selectedFunds.indexOf(fund);
            if (index < 0) {
                return;
            }
            order.selectedFunds.splice(index, 1);
        };

        order.hasSelectedFund = function (isinCurrencyId) {
            var matchFund = function (fund) {
                return fund.isinCurrencyId === isinCurrencyId;
            };
            return _.find(order.selectedFunds, matchFund) !== undefined;
        };


        //Information functions
        order.selectedFundsMonthlyAmountSum = function () {
            var sum = 0;
            order.selectedFunds.forEach(function (fund) {
                if (fund.monthlyAmount != null) {
                    sum += Number(fund.monthlyAmount);
                }
            });
            return sum;
        };

        order.selectedFundsInitialAmountSum = function () {
            var sum = 0;
            order.selectedFunds.forEach(function (fund) {
                if (fund.initialAmount != null) {
                    sum += Number(fund.initialAmount);
                }
            });
            return sum;
        };


        //Validation
        function validationError(title, message) {
            return {
                title: title,
                message: message
            };
        }

        order.dataValidation = function (portfolioSelected, selectedPortfolio) {
            if (portfolioSelected) {
                if (!order.portfolio.portfolioName || order.portfolio.portfolioTypeId === undefined || !order.portfolio.portfolioTypeName || !selectedPortfolio) {
                    return validationError('maxfonder_fundPurchase_portfolio_errTitle', 'maxfonder_fundPurchase_noPortfolio_err_message');
                }
            } else {
                if (order.selectedFunds.length === 0) {
                    return validationError('maxfonder_fundPurchase_fund_errTitle', 'maxfonder_fundPurchase_noFunds_err_message');
                }
            }

            function isPositivInteger(str) {
                var n = Math.floor(Number(str));
                //Removing leading 0
                while (str && str.charAt(0) === '0') {
                    str = str.substr(1);
                }
                return String(n) === str && n > 0;
            }

            function initialAmountAboveMin() {
                return isPositivInteger(order.portfolio.initialAmount) && (Number(order.portfolio.initialAmount) >= selectedPortfolio.minInvestment);
            }

            function monthlyAmountAboveMin() {
                return isPositivInteger(order.portfolio.monthlyAmount) && (Number(order.portfolio.monthlyAmount) >= selectedPortfolio.minMonthlyInvestment);
            }

            function hasNoValue(value) {
                var number = Number(value);
                return Number(value) === 0 || _.isNaN(number);
            }

            if (portfolioSelected) {
                var validAmount = false;
                if (initialAmountAboveMin()) {
                    validAmount = monthlyAmountAboveMin() || hasNoValue(order.portfolio.monthlyAmount);
                } else if (monthlyAmountAboveMin()) {
                    validAmount = initialAmountAboveMin() || hasNoValue(order.portfolio.initialAmount);
                }

                if (!validAmount) {
                    return validationError('maxfonder_fundPurchase_portfolio_errTitle', $filter('translate')('maxfonder_fundPurchase_amountPortfolio_err_message',
                        {minMonthly: selectedPortfolio.minMonthlyInvestment, minInitial : selectedPortfolio.minInvestment}), 'dataValidationErr');
                }
            } else {
                var hasInvalidFundAmount = _.some(order.selectedFunds, function (fundPurchase) {
                    return !isPositivInteger(fundPurchase.initialAmount) && !isPositivInteger(fundPurchase.monthlyAmount);
                });

                if (hasInvalidFundAmount) {
                    return validationError('maxfonder_fundPurchase_fund_errTitle', 'maxfonder_fundPurchase_amountFund_err_message');
                }
            }

            return undefined;
        };

        return order;
    }

    service.saveOrder = function (order) {
        sessionStorage.maxfonderOrder = JSON.stringify(order);
    };

    service.deleteOrder = function () {
        sessionStorage.removeItem('maxfonderOrder');
    };

    service.getSavedOrder = function () {
        if (sessionStorage.maxfonderOrder) {
            var savedOrder = createOrder(JSON.parse(sessionStorage.maxfonderOrder));
            if (savedOrder && savedOrder.ssn === $rootScope.user.ssn) {
                return savedOrder;
            }
        }
        return null;
    };

    service.getOrCreateOrder = function () {
        var savedOrder = service.getSavedOrder();
        if (savedOrder) {
            return savedOrder;
        }
        return createOrder();
    };


    function createPdfResult(response) {
        return {
            pdf: BlobService.createPdfBlobUrl(response.data),
            depositNumber: response.depositNumber
        };
    }

    function formatPurchaseRequest(order) {
        var request = {
            fundPurchases: order.fundPurchases
        };

        if (order.depositNumber) {
            request.depositNumber = order.depositNumber;
        } else if (order.pdfResult && order.pdfResult.depositNumber) {
            // add possible deposit number created by registration and stored on bekraftelse page
            request.depositNumber = order.pdfResult.depositNumber;
        }

        if (order.debitMonthDay) {
            request.debitMonthDay = order.debitMonthDay;
        }

        return request;
    }

    function formatRegistrationAndPurchaseRequest(order, registration) {
        return {
            registration: RegistrationService.formatRequest(registration),
            purchase: formatPurchaseRequest(order)
        };
    }

    service.generatePDFWithoutSendingOrder = function (order) {
        var generatePdfCall,
            deferred = $q.defer(),
            request;

        if (RegistrationService.hasPendingSavedRegistration()) {
            request = formatRegistrationAndPurchaseRequest(order, RegistrationService.getSavedRegistration());
            generatePdfCall = resource.createDepositAndGenerateMergedPdf(request);
        } else {
            request = formatPurchaseRequest(order);
            generatePdfCall = resource.generatePurchasePdf(request);
        }

        generatePdfCall.$promise.then(
            function (response) {
                if (RegistrationService.hasPendingSavedRegistration()) {
                    RegistrationService.pendingSavedRegistrationSubmitted();
                }
                deferred.resolve(createPdfResult(response));
            }, function () {
                deferred.reject();
            }
        );

        return deferred.promise;
    };

    service.generatePDFForPrint = function (order, registration) {
        var generatePdfCall,
            deferred = $q.defer();

        if (registration) {
            generatePdfCall = resource.generateMergedPdf(formatRegistrationAndPurchaseRequest(order, registration));
        } else {
            generatePdfCall = resource.generatePurchasePdf(formatPurchaseRequest(order));
        }

        generatePdfCall.$promise.then(
            function (response) {
                deferred.resolve(createPdfResult(response));
            }, function () {
                deferred.reject();
            }
        );

        return deferred.promise;
    };

    service.send = function (order, signature, signatureVisibleData, signatureToken, ipAddress, bankIdLogEntryId) {

        var signatureWithToken = {
            signatureToken: signatureToken,
            signature: {
                signature: signature,
                visibleData: signatureVisibleData
            }
        };

        var signatureReference = {
            bankIdLogEntryId: bankIdLogEntryId,
            ipAddress: ipAddress
        };

        if (RegistrationService.hasPendingSavedRegistration()) {
            return service.sendRegistrationAndPurchase(order, RegistrationService.getSavedRegistration(), signatureWithToken, signatureReference);
        } else {
            return service.sendPurchase(order, signatureWithToken, signatureReference);
        }
    };

    service.sendRegistrationAndPurchase = function (order, registration, signatureWithToken, signatureReference) {

        var request = formatRegistrationAndPurchaseRequest(order, registration);
        request.signatureWithToken = signatureWithToken;
        request.signatureReference = signatureReference;

        var deferred = $q.defer();
        resource.createDepositAndSendRegistrationAndPurchase(request).$promise.then(
            function (response) {
                RegistrationService.pendingSavedRegistrationSubmitted();
                deferred.resolve(response.documentId);
            }, function () {
                deferred.reject();
            }
        );
        return deferred.promise;
    };

    service.sendPurchase = function (order, signatureWithToken, signatureReference) {

        var request = formatPurchaseRequest(order);
        request.signatureWithToken = signatureWithToken;
        request.signatureReference = signatureReference;

        var deferred = $q.defer();
        resource.sendPurchase(request).$promise.then(
            function (response) {
                deferred.resolve(response.documentId);
            }, function () {
                deferred.reject();
            }
        );
        return deferred.promise;
    };

    service.abort = function () {
        if (RegistrationService.hasPendingSavedRegistration()) {
            RegistrationService.deleteRegistration();
        }
        service.deleteOrder();
    };

    return service;
});
